<!-- =========================================================================================
  File Name: Todo.vue
  Description: Todo Application to keep you ahead of time
  ----------------------------------------------------------------------------------------
  Item Name: Legal Board Services
    Author: Enkompass
  Author URL: https://www.enkompass.net
========================================================================================== -->


<template>
    <div id="dt" class="vs-con-loading__container">

      <div id="todo-app" class="border border-solid d-theme-border-grey-light rounded relative overflow-hidden">
        <vs-sidebar class="items-no-padding" parent="#todo-app" :click-not-close="clickNotClose" :hidden-background="clickNotClose" v-model="isSidebarActive">

            <div  :key="$vs.rtl">
                <todo-filters @closeSidebar="toggleTodoSidebar"></todo-filters>
            </div>

        </vs-sidebar>
        <div :class="{'sidebar-spacer': clickNotClose}" class="no-scroll-content border border-r-0 border-b-0 border-t-0 border-solid d-theme-border-grey-light no-scroll-content">

            <div class="flex d-theme-dark-bg items-center border border-l-0 border-r-0 border-t-0 border-solid d-theme-border-grey-light p-2 lg:hidden">

                <!-- TOGGLE SIDEBAR BUTTON -->
                <feather-icon class="md:inline-flex ml-4 mr-4 cursor-pointer" icon="MenuIcon" @click.stop="toggleTodoSidebar(true)" />

                <router-link tag="div" to="/" class="vx-logo cursor-pointer flex items-center">
                  <logo class="w-10 mr-4 fill-current text-primary" />
                  <span class="vx-logo-text text-primary">Legal Board Services</span>
                </router-link>

            </div>

            <!-- TODO LIST -->
            <div id="scroller" ref="taskListPS" :key="$vs.rtl">

              <template>
                <div id="data-list-list-view" v-if="itemData" class="data-list-container mt-10">

                  <div slot="header" id="addnewfirmBtn" class="flex flex-wrap-reverse items-center flex-grow justify-between">
                    <div class="flex flex-wrap-reverse items-center data-list-btn-container">
                      <!-- ADD NEW -->
                      <vs-button class="ml-2 mr-4 mb-4 flex items-center justify-center text-sm ph-4 flex-grow-0" type="border"
                                 v-if="showAddFirm" :taskId="{}" @click.stop="showDisplayPromptAdd({}, 'account')"
                      >
                        Add New
                      </vs-button>
                      <!-- ADD NEW -->
                      <vs-button class="ml-2 mr-4 mb-4 flex items-center justify-center text-sm ph-4 flex-grow-0" type="border"
                                 v-if="menuType !== 'users'" @click.stop="generatePDFReport"
                      >
                        PDF Report
                      </vs-button>
                    </div>
                  </div>

                  <vs-table 
                    search 
                    :data="itemData" 
                    ref="table" 
                    id="data-table"
                    @selected="handleSelectedRow"
                  >

                    <template slot="thead" v-if="menuItems[menuType]">
                      <vs-th sort-key="name" v-for="menu in menuItems[menuType]" :key="menu.id">{{menu.label}}</vs-th>
                      <vs-th>Actions</vs-th>
                    </template>

                      <template slot-scope="{data}">
                        <tbody v-if="data">
                          <vs-tr class="cursor-pointer" :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                            <vs-td v-for="menu in menuItems[menuType]" :key="menu.id">
                              <p v-if="!menu.formatter">{{tr[menu.id]}}</p>
                              <p v-else>{{menu.formatter(tr[menu.id], tr)}}</p>
                            </vs-td>

                            <vs-td class="whitespace-no-wrap">

                              <vx-tooltip text="Edit" position="left">
                                <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" :taskId="tr" @click.stop="showDisplayPrompt(tr)" :key="String(tr.name) + String(12345)" />
                              </vx-tooltip>

                              <vx-tooltip text="Delete" position="left">
                                <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="showDisplayPromptDelete(tr)" />
                              </vx-tooltip>
                              <!-- <feather-icon v-if="showAddFirm" icon="FileIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" :taskId="tr" class="ml-2" @click.stop="showDisplayPromptAdd(tr, 'case')" /> -->

                              <span v-if="isAccountsView">
                                <vx-tooltip text="Add User" position="left">
                                  <feather-icon icon="UserPlusIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" :taskId="tr" class="ml-2" @click.stop="showDisplayPromptAdd(tr, 'user')" />
                                </vx-tooltip>

                                <vx-tooltip text="Add Case" position="left">
                                  <feather-icon icon="FolderPlusIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" :taskId="tr" class="ml-2" @click.stop="showDisplayPromptAdd(tr, 'case')" />
                                </vx-tooltip>

                                <vx-tooltip text="Show Cases" position="left">
                                  <feather-icon icon="BriefcaseIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="showFirmData(tr, 'cases')" />
                                </vx-tooltip>
                                <vx-tooltip text="Show Users" position="left">
                                  <feather-icon icon="UsersIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="showFirmData(tr, 'users')" />
                                </vx-tooltip>
                              </span>
                              <span v-if="isCasesView">
                                <vx-tooltip text="Upload Document" position="left" color="#1a1a1a">
                                  <feather-icon icon="UploadCloudIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2"
                                                @click.stop="addNewDocumentModal(tr)" />
                                </vx-tooltip>
                                <vx-tooltip text="Uploaded Document List" position="left">
                                  <feather-icon icon="ListIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2"
                                                @click.stop="showCaseDocumentList(tr)" />
                                </vx-tooltip>
                                <vx-tooltip text="Bates Report" position="left">
                                  <feather-icon icon="BarChartIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2"
                                                @click.stop="showCaseDocumentFullBatesReports(tr)" />
                                </vx-tooltip>
                              </span>
                            </vs-td>
                          </vs-tr>
                        </tbody>
                      </template>
                  </vs-table>
                </div>
              </template>
            </div>
            <!-- /TODO LIST -->

        </div>

        <!-- EDIT TODO DIALOG -->
<!--        <todo-data-modal :displayDataModal="displayDataModal" :taskId="taskIdToEdit" @hideDisplayPrompt="hidePrompt"-->
<!--                         v-if="displayDataModal" :activeType="this.menuType"></todo-data-modal>-->
        <case-document-upload :displayPrompt="this.documentPopup" @hideDisplayPrompt="hideNewDocumentModel"
                              v-if="this.documentPopup" :taskRecord="this.currentItem"></case-document-upload>
        <todo-edit :displayPrompt="displayPrompt" :taskId="taskIdToEdit" @hideDisplayPrompt="hideEditPrompt"
                   v-if="displayPrompt" :activeType="this.menuType"></todo-edit>
        <todo-delete :displayDeletePrompt="displayDeletePrompt" :taskId="taskIdToEdit" @hideDisplayPrompt="hideDeletePrompt"
                     :activeType="this.menuType" v-if="displayDeletePrompt"></todo-delete>
        <todo-add-new :displayAddPrompt="displayAddPrompt" :taskId="taskIdToEdit" @hideDisplayPrompt="hideAddNewPrompt"
                      v-if="displayAddPrompt"></todo-add-new>
    </div>
      <div class="p-5"><Metrics /></div>
    </div>
</template>

<script>
import _ from 'lodash'
import Metrics from '@/views/apps/dashboard/Metrics.vue'
import moduleTodo          from '@/store/todo/moduleTodo.js'
import TodoDataModal       from './TodoDataModal.vue'
import TodoAddNew          from './TodoAddNew.vue'
import TodoTask            from './TodoTask.vue'
import TodoFilters         from './TodoFilters.vue'
import TodoEdit            from './TodoEdit.vue'
import TodoDelete          from './TodoDelete.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Logo                 from '@/layouts/components/Logo.vue'
import CaseDocumentUpload from './CaseDocumentUpload.vue'

export default {
  data () {
    return {
      menuItems: {
        accounts : [
          {label: 'Law Firm', id: "name"},
          {label: 'Firm Alias', id: "alias"},
          {label: 'Created', id: "createdOn", formatter: this.formatDate},
          {label: 'Updated', id: "updatedOn", formatter: this.formatDate}
        ],
        cases : [
          {label: 'Case Name', id: "name"},
          {label: 'Firm Name', id: "accountId", formatter: this.renderFirmName},
          {label: 'Created', id: "createdOn", formatter: this.formatDate},
          {label: 'Slicer Queue', id: "readyForSlicing"}
        ],
        users : [
          {label: 'User Name', id: "name"},
          {label: 'Email', id: "email"},
          {label: 'Firm Name', id: "accountId", formatter: this.renderFirmName},
          {label: 'Created', id: "createdOn", formatter: this.formatDate},
          {label: 'Updated', id: "updatedOn", formatter: this.formatDate}
        ]
      },
      selected: [],
      currentItem: [],
      itemsPerPage: 5,
      currFilter           : '',
      clickNotClose        : true,
      displayPrompt        : false,
      displayDeletePrompt  : false,
      displayDataModal     : false,
      displayAddPrompt     : false,
      documentPopup     : false,
      taskIdToEdit         : 0,
      isSidebarActive      : true,
      settings : {
        maxScrollbarLength : 60,
        wheelSpeed         : 0.30
      }
    }
  },
  watch: {
    windowWidth () {
      this.setSidebarWidth()
    }
  },
  computed: {
    itemData (e) {
      try {
        return this.$store.state.todo.currentData.items
      } catch(e){
        return []
      }
    },
    firmData (e) {
      try {
        return this.$store.state.todo.lawFirmData.items
      } catch(e){
        return []
      }
    },
    menuType (e) {
      return this.$store.state.todo.menuType
    },
    isAccountsView () {
      return this.$store.state.todo.menuType == 'accounts'
    },
    isCasesView () {
      return this.$store.state.todo.menuType == 'cases'
    },
    showEditOption () {
      return (this.$store.state.todo.menuType == 'accounts' || this.$store.state.todo.menuType == 'users')
    },
    showAddFirm () {
      return this.$store.state.todo.menuType == 'accounts'
    },
    todo ()         { return this.$store.state.todo.todoArray              },
    scrollbarTag () { return this.$store.getters.scrollbarTag              },
    windowWidth ()  { return this.$store.state.windowWidth                 }
  },
  methods: {
    slicerDocumentList(row) {
      this.$router.push({name: "case-document-readyforslicing", params: {accountId: row.accountId, caseId: row.uuid}});
    },
    refreshData() {
      this.$vs.loading({
        container: '#dt',
        scale: 0.6
      })
      this.$store.dispatch('todo/fetchData', this.menuType)
        .then(response => {
          this.$vs.loading.close('#dt > .con-vs-loading')
        }).catch(error => {
          this.$vs.loading.close('#dt > .con-vs-loading')
          this.$vs.notify({
            color: 'danger',
            title: 'Error...',
            text: error && error.message ? error.message : 'Error refreshing data.'
          });
        });
    },
    formatDate(value) {
      if (value && this.moment && this.moment(value).isValid())
        return this.moment(value).format('MM/DD/YYYY')
      else return ''
    },
    showDisplayPrompt (itemId) {
      this.taskIdToEdit  = itemId
      if (this.menuType === 'accounts' && itemId && itemId.uuid) {
        this.taskIdToEdit.accountId = itemId.uuid
      }
      this.displayPrompt = true
    },
    showDisplayPromptDelete (itemId) {
      this.taskIdToEdit  = itemId
      this.taskIdToEdit.menuType  = this.menuType
      this.displayDeletePrompt = true
    },
    showDisplayPromptAdd (itemId, addType) {
      itemId.addType = addType
      this.taskIdToEdit  = itemId
      this.displayAddPrompt = true
    },
    showFirmData(firm, display_type){
      firm.display_type = display_type
      this.taskIdToEdit  = firm
      this.taskIdToEdit.display_type = display_type
      // this.displayDataModal = true
      if(firm.uuid) {
        this.$router.push("/accounts/" + firm.uuid + "/" + display_type);
      }
    },
    handleSelectedRow(row) {
      switch (this.menuType) {
        case 'accounts':
          this.showFirmData(row, 'cases')
          break
        case 'cases':
          this.showCaseDocumentList(row)
          break
      }
    },
    hideNewDocumentModel() {
      this.documentPopup = false;
    },
    hideEditPrompt (data) {
      this.displayPrompt = false
      if (data && data.shouldRefresh && data.menuType) {
        this.refreshData();
      }
    },
    hideDeletePrompt (data) {
      this.displayDeletePrompt = false
      if (data && data.shouldRefresh && data.menuType) {
        this.refreshData()
      }
    },
    hideAddNewPrompt (data) {
      this.displayAddPrompt = false
      if (data && data.shouldRefresh && data.menuType) {
        console.log(data.menuType, this.menuType, this.menuType === data.menuType)
        this.refreshData()
      }
    },
    setSidebarWidth () {
      if (this.windowWidth < 992) {
        this.isSidebarActive = this.clickNotClose = false
      } else {
        this.isSidebarActive = this.clickNotClose = true
      }
    },
    toggleTodoSidebar (value = false) {
      if (!value && this.clickNotClose) return
      this.isSidebarActive = value
    },
    hidePrompt() {
      this.displayDataModal = false
    },
    renderFirmName(firmId) {
      try {
        let index = _.findIndex(this.firmData, {uuid: firmId});
        if (index > -1) {
          return this.firmData[index].name;
        }
      } catch(e){
        return '';
      }
    },
    generatePDFReport() {
      let htmlString = `
      <style>
        table {
          font-family: Arial, Helvetica, sans-serif;
          border-collapse: collapse;
          width: 80%;
          margin: auto;
        }

        table td, table th {
          border: 1px solid #ddd;
          padding: 8px;
        }

        table tr:nth-child(even){background-color: #f2f2f2;}

        table th {
          padding-top: 12px;
          padding-bottom: 12px;
          text-align: left;
          background-color: #4CAF50;
          color: white;
        }
      </style>
      `;
      let vm = this;
      htmlString += "<table><thead>";
      this.menuItems[this.menuType].map(item => {
        htmlString += ("<th>" + item.label + "</th>");
      });
      htmlString += "</thead>";
      htmlString += "<tbody>";
      const items = this.menuType === 'accounts' ? this.firmData : this.itemData
      if (items && items.length > 0) {
        if (this.menuType === 'cases') {
          let firmCases = _.groupBy(items, 'accountId');
          Object.keys(firmCases).map((firmId) => {
            htmlString += firmCases[firmId].map(row => {
              let rowString = "<tr>";
              this.menuItems[this.menuType].map(item => {
                let value = item.formatter ? item.formatter(row[item.id]) : row[item.id];
                rowString += ("<td>" + value + "</td>");
              });
              rowString += "</tr>";
              return rowString;
            }).join(" ");
          });
        } else {

        }
      }
      htmlString += "</tbody></table>";
      const printWindow = window.open('', 'Print law firms list');
      printWindow.document.write('<html><head><title>Print law firms list</title>');
      printWindow.document.write('</head><body>');
      printWindow.document.write(htmlString);
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      printWindow.print();
      printWindow.focus();
    },
    addNewDocumentModal(data) {
      this.currentItem = data
      this.documentPopup = true;
    },
    showCaseDocumentFullBatesReports(data) {
      this.$router.push({name: "case-document-full-bates", params: {accountId: data.accountId, caseId: data.uuid}});
    },
    showCaseDocumentList(data) {
      this.$router.push({
        name: "account-case-documents", 
        params: {accountId: data.accountId, caseId: data.uuid},
        query: {source: "home"}
      });
    }
  },
  components: {
    TodoDataModal,
    TodoAddNew,
    TodoTask,
    TodoFilters,
    TodoEdit,
    TodoDelete,
    VuePerfectScrollbar,
    Metrics,
    Logo,
    CaseDocumentUpload
  },
  created () {

    if (!this.$store.hasModule("todo")) {
        this.$store.registerModule('todo', moduleTodo)
    }
    this.setSidebarWidth()

    const filter = this.$route.params.filter

    this.$store.commit('todo/UPDATE_TODO_FILTER', filter)
  },
  beforeUpdate () {
    this.currFilter = this.$route.params.filter
  },
  beforeDestroy () {
    this.$store.unregisterModule('todo')
  },
  mounted () {
    this.$vs.loading({container: '#dt', scale: 0.6})
    this.$store.dispatch('todo/fetchData', 'accounts')
      .then(response => {
        this.$vs.loading.close('#dt > .con-vs-loading')
        if (this.$route.query.selectedTab && this.$route.query.selectedTab !== 'accounts') {
          this.$vs.loading({container: '#dt', scale: 0.6})
          this.$store.dispatch('todo/fetchData', this.$route.query.selectedTab)
            .then(response => {
              this.$vs.loading.close('#dt > .con-vs-loading')
            })
        }
      })
  }
}
</script>


<style lang="scss">
@import "@/assets/scss/vuexy/apps/todo.scss";

#todo-app, #todo-app .vs-sidebar.vs-sidebar-parent, #todo-app .ps-container {
  height: calc(75vh - 120px) !important;
}

.no-scroll-content .sidebar-spacer{
  height: calc(75vh - 120px);
}

#todo-app {
  margin-bottom: 24px;
}

#scroller{
  overflow-y: auto;
  height: calc(75vh - 120px);
}

#addnewfirmBtn{
    width: 50%;
    float: left;
    margin: auto 1rem;
}

#data-list-list-view .vs-con-table .vs-table--header {
    margin: 1rem;
    font-size: 12px;
}

.vs-table--search {
  max-width: 100%;
  width: 100%;
}

#data-list-list-view .vs-con-table .vs-table--header .vs-table--search .vs-table--search-input {
  padding: 0.65rem 2rem;
  width: 100%;
  margin-right: 18px;
}

#data-list-list-view .vs-con-table .vs-table--header .vs-table--search .vs-table--search-input + i,
#data-list-list-view .vs-con-table .vs-table--header .vs-table--search .vs-table--search-input:focus + i {
  left: 0.5rem;
}

.product-category{
  font-size: 13px;
}

.vs-tooltip{
  padding: 8px;
  background: #1a1a1a;
}
.con-vs-tooltip {
  display: inline-block;
}

@media (min-width: 992px) {
  .sidebar-logo-container {
    display: none;
  }
  .vs-sidebar {
    max-width: 200px;
  }
  .sidebar-spacer {
    margin-left: 200px;
  }
}
.vs-table-text {
  font-size: 11px;
}
</style>