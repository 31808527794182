<!-- =========================================================================================
    File Name: Metrics.vue
    Description: Dashboard Metrics Display Component
    ----------------------------------------------------------------------------------------
    Item Name: Legal Board Services
    Author: Enkompass
    Author URL: https://www.enkompass.net
============================================================================================== -->

<template>
  <div>
    <div class="vx-row">
        <div class="vx-col w-1/2 md:w-1/3 xl:w-1/4">
            <statistics-card-line
              id="AccountRecord"
              hideChart
              class="mb-base vs-con-loading__container mb-1"
              icon="BriefcaseIcon"
              :iconRight="true"
              :statistic="metricsData.AccountRecord.Count"
              statisticTitle="Law Firms" />
        </div>
        <div class="vx-col w-1/2 md:w-1/3 xl:w-1/4">
            <statistics-card-line
              id="CaseRecord"
              hideChart
              :iconRight="true"
              class="mb-base vs-con-loading__container"
              icon="TrelloIcon"
              statisticTitle="Cases"
              :statistic="metricsData.CaseRecord.Count"
              color="success" />
        </div>

        <div class="vx-col w-1/2 md:w-1/3 xl:w-1/4">
            <statistics-card-line
              id="documentRecord"
              :iconRight="true"
              hideChart
              class="mb-base vs-con-loading__container"
              icon="FileTextIcon"
              :statistic="metricsData.documentRecord.Count"
              statisticTitle="Documents"
              color="warning" />
        </div>
        <div class="vx-col w-1/2 md:w-1/3 xl:w-1/4">
            <statistics-card-line
              id="UserRecord"
              :iconRight="true"
              hideChart
              class="mb-base vs-con-loading__container"
              icon="UserIcon"
              :statistic="metricsData.UserRecord.Count"
              statisticTitle="Users"
              color="success" />
        </div>
    </div>
  </div>
</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import moduleDashboard from '@/store/dashboard/moduleDashboard.js'

export default{
  components: {
    StatisticsCardLine
  },
  data () {
    return {
      loadingBackgroundColor : '#FFF',
      scale: 1,
      opacity: 1
    }
  },
  computed : {
    metricsData () {
      if(this.$store.state.todo.metricsData && this.$store.state.todo.metricsData.count){
        return this.$store.state.todo.metricsData.count
      }
      return {
        UserRecord: { Count: 0 },
        AccountRecord: { Count: 0 },
        CaseRecord: { Count: 0 },
        documentRecord: { Count: 0 }
      }
    },
  },
  methods : {
    closeCardAnimation (card) {
      card.removeRefreshAnimation(3000)
    }
  },
  mounted () {
    this.$vs.loading({
      container: '#AccountRecord',
      scale: this.scale,
      opacity: this.opacity
    })
    this.$vs.loading({
      container: '#CaseRecord',
      scale: this.scale,
      opacity: this.opacity
    })
    this.$vs.loading({
      container: '#documentRecord',
      scale: this.scale,
      opacity: this.opacity
    })
    this.$vs.loading({
      container: '#UserRecord',
      scale: this.scale,
      opacity: this.opacity
    })
  },
  created () {
    this.$store.dispatch('todo/fetchMetrics')
      .then(data => {
        this.$vs.loading.close(['#AccountRecord > .con-vs-loading', '#CaseRecord > .con-vs-loading', '#documentRecord > .con-vs-loading', '#UserRecord > .con-vs-loading'])
      });
  }
}
</script>
