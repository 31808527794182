<!-- =========================================================================================
    File Name: Dashboard.vue
    Description: Dashboard - LBS
    ----------------------------------------------------------------------------------------
    Item Name: Legal Board Services
      Author: Enkompass
    Author URL: https://www.enkompass.net
========================================================================================== -->

<template>
  <div>
    <Todo />
  </div>
</template>

<script>
import Todo from './apps/todo/Todo.vue'

export default{
  components: {
    Todo,
  },
  data () {
    return {
      subscribersGained: {},
      revenueGenerated: {},
      quarterlySales: {},
      ordersRecevied: {}
    }
  },
  computed: {
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  methods : {
    closeCardAnimationDemo (card) {
      card.removeRefreshAnimation(3000)
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
.content-wrapper{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
</style>
