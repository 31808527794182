<!-- =========================================================================================
    File Name: TodoAddNew.vue
    Description: Add new todo component
    ----------------------------------------------------------------------------------------
    Item Name: Legal Board Services
      Author: Enkompass
    Author URL: https://www.enkompass.net
========================================================================================== -->


<template>
    <div class="todo__filters-container">
        <vs-row vs-type="flex" vs-justify="flex-end" class="lg:hidden mt-2">
          <feather-icon class="md:inline-flex ml-auto mr-4 cursor-pointer" icon="XIcon" @click.stop="toggleTodoSidebar(false)" />
        </vs-row>

        <!-- all -->
        <div class="px-6 py-4 mt-5 cursor-pointer" @click="fetchData('accounts')">
          <feather-icon icon="TrelloIcon" :svgClasses="[{'text-primary stroke-current': currentActive == 'accounts'}, 'h-6 w-6']"></feather-icon>
          <span :class="[{'text-primary': currentActive == 'accounts'}, 'text-sm ml-3 mt-1 menu-text']">Law Firms</span>
        </div>

        <div class="px-6 py-4 cursor-pointer" @click="fetchData('cases')">
          <feather-icon icon="FileIcon" :svgClasses="[{'text-primary stroke-current': currentActive == 'cases'}, 'h-6 w-6']"></feather-icon>
          <span :class="[{'text-primary': currentActive == 'cases'}, 'text-sm ml-3 mt-1 menu-text']">Cases</span>
        </div>

        <div class="px-6 py-4 cursor-pointer" @click="fetchData('users')">
          <feather-icon icon="UsersIcon" :svgClasses="[{'text-primary stroke-current': currentActive == 'users'}, 'h-6 w-6']"></feather-icon>
          <span :class="[{'text-primary': currentActive == 'users'}, 'text-sm ml-3 mt-1 menu-text']">Users</span>
        </div>
    </div>
</template>

<script>

export default{
  data () {
    return {
      currentActive:'accounts',
      todoFilters: [
        { filterName: 'Starred', filter: 'starred', icon: 'StarIcon' },
        { filterName: 'Important', filter: 'important', icon: 'InfoIcon' },
        { filterName: 'Completed', filter: 'completed', icon: 'CheckIcon' },
        { filterName: 'Trashed', filter: 'trashed', icon: 'TrashIcon' }
      ]
    }
  },
  methods: {
    toggleTodoSidebar(val) {
      this.$emit('closeSidebar', val)
    },
    isActive(page){
      if(this.currentActive == page){
        return 'text-primary'
      }
    },
    fetchData(item){
      //Set active
      this.currentActive = item
      //Show loading
      this.$vs.loading({ container: '#dt'})
      this.$store.dispatch('todo/fetchData', item)
        .then(r => {
          this.$vs.loading.close('#dt > .con-vs-loading')
        })
    }
  },
  computed: {
    taskTags () {
      return this.$store.state.todo.taskTags
    },
    todoFilter () {
      return this.$route.params.filter
    },
    baseUrl () {
      const path = this.$route.path
      return path.slice(0, path.lastIndexOf('/'))
    }
  }
}
</script>

<style lang="scss">

.todo__filters-container .menu-text{
  position: absolute;
  margin-top: 0;
}
</style>
