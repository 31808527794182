<!-- =========================================================================================
    File Name: TodoDataModal.vue
    Description: View Data component
    ----------------------------------------------------------------------------------------
    Item Name: Legalboard Services
      Author: Enkopmpass
    Author URL: http://www.enkompass.net
========================================================================================== -->


<template>
  <vs-popup fullscreen :title="title" @close="init" :active.sync="activePrompt">
    <div id="data-model-container" class="vs-con-loading__container">

      <vs-table stripe search :data="itemData">
        <div slot="header" id="addnewcaseBtn" class="flex flex-wrap-reverse items-center flex-grow justify-between">
          <div class="flex flex-wrap-reverse items-center data-list-btn-container">
            <!-- ADD NEW -->
            <div class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg
              font-medium text-base text-primary border border-solid border-primary" @click.stop="addNewDataModel()">
              <span class="ml-2 text-base text-primary">Add New</span>
            </div>
          </div>
        </div>

        <template slot="thead" v-if="menuItems[menuType]">
          <vs-th sort-key="name" v-for="menu in menuItems[menuType]" :key="menu">{{menu}}</vs-th>
        </template>

          <template slot-scope="{data}">
            <tbody v-if="data">

              <vs-tr class="cursor-pointer" :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                <vs-td>
                  <p class="product-name font-medium">{{ tr.name }}</p>
                </vs-td>

                <vs-td v-if="menuType != 'accounts'">
                  <p v-if="tr.encryptionKey" class="encryption-key">{{ tr.encryptionKey }}</p>
                  <p v-if="tr.accountId" class="encryption-key">{{ tr.uuid }}</p>
                </vs-td>

                <vs-td>
                  <p class="encryption-key">{{ formatDate(tr.createdOn) }}</p>
                </vs-td>

                <vs-td>
                  <p class="product-price">{{ formatDate(tr.createdOn) }}</p>
                </vs-td>

                <vs-td>
                  <vx-tooltip text="Edit" position="left">
                    <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2"
                                  @click.stop="editItem(tr)" />
                  </vx-tooltip>

                  <vx-tooltip text="Delete" position="left">
                    <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2"
                                  @click.stop="deleteItem(tr)" />
                  </vx-tooltip>
                </vs-td>

              </vs-tr>
            </tbody>
          </template>

      </vs-table>

      <div class="centerx">
        <todo-edit :displayPrompt="editPopup" @hideDisplayPrompt="handleResponse" v-if="this.editPopup"
                   :taskId="this.currentItem" :activeType="this.menuType"></todo-edit>
        <todo-delete :displayDeletePrompt="deletePopup" @hideDisplayPrompt="hidePrompt" v-if="this.deletePopup"
                     :taskId="this.currentItem" :activeType="this.menuType"></todo-delete>
        <todo-add-new :displayAddPrompt="addPopup" :taskId="this.taskLocal" @hideDisplayPrompt="hidePrompt"
                      :activeType="this.menuType" v-if="this.addPopup"></todo-add-new>
      </div>

    </div>
  </vs-popup>
</template>

<script>
import TodoDelete from './TodoDelete.vue'
import TodoEdit from './TodoEdit.vue'
import TodoAddNew from './TodoAddNew.vue'

export default {
  components: {
    TodoDelete,
    TodoEdit,
    TodoAddNew
  },
  props: {
    displayDataModal: {
      type: Boolean,
      required: true
    },
    taskId: {
      required: true
    }
  },
  data () {
    return {
      itemData: [],
      currentItem:[],
      menuType: null,
      menuItems: {
        cases : ['Case Name', 'Case Id', 'Created', 'Updated', 'Actions'],
        users : ['User Name', 'Email', 'Created', 'Updated', 'Actions']
      },
      editPopup:false,
      addPopup:false,
      deletePopup:false,
      taskLocal: {}
    }
  },
  created() {
    this.menuType = this.taskId.display_type
    this.$store.dispatch('todo/fetchFirmData', this.taskId)
      .then(response => {
        this.itemData = response.items
        this.$vs.loading.close('#data-model-container > .con-vs-loading')
      });
  },
  mounted () {
    if ((this.menuType === 'cases' || this.menuType === 'users') && this.taskId && this.taskId.uuid) {
      this.taskLocal = {
        firmId: this.taskId.uuid,
      };
      this.taskLocal['addType'] = this.menuType === 'cases' ? 'case' : 'user';
    }
    this.$vs.loading({
      container: '#data-model-container',
    })
  },
  computed: {
    activePrompt: {
      get () {
        return this.displayDataModal
      },
      set (value) {
        this.$emit('hideDisplayPrompt', value)
      }
    },
    title () {
      return ('View ' + this.taskId.display_type)
    }
  },
  methods: {
    addNewDataModel() {
      this.activePrompt = false;
      this.addPopup = true;
      console.log(this.addPopup, this.taskLocal);
    },
    hidePrompt() {
      this.addPopup = false;
      this.deletePopup = false;
    },
    handleResponse(data){
      this.itemData = data
      this.editPopup = false
      this.$store.commit('todo/UPDATE_DATA_TYPE', 'accounts')
    },
    formatDate(value) {
      if (value && this.moment)
        return this.moment(value).format('DD/MM/YYYY')
      else return ''
    },
    deleteItem(item){
      this.currentItem = item
      this.currentItem.display_type = this.taskId.display_type
      this.deletePopup = true
    },
    editItem(item){
      this.currentItem = item
      this.currentItem.display_type = this.taskId.display_type
      this.editPopup = true
    },
    init(){
      this.$store.commit('todo/UPDATE_DATA_TYPE', 'accounts')
    }
  }
}
</script>
